#scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    font-size: 18px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1000;
  }
  
  #scrollToTopButton.show {
    display: block;
  }
  
  #scrollToTopButton:hover {
    background-color: #555;
  }
  