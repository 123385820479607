:root {
    --Background2-color: rgba(255, 255, 255, 0.9);
    --Text2-color: #777;
    --Box2-color: rgba(0, 0, 0, 0.1);
  }

.impressum-card-content {
    width: 75%;
    padding: 20px;
    background-color: var(--Background2-color);
    color: var(--Text2-color);
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--Box2-color);
    margin: 10px;
  }