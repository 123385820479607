body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  .error-modal .modal-content {
    background-color: red;
  }

  .error-modal .modal-header,
  .error-modal .modal-footer {
    border-color: red;
  }

  .error-modal p {
    color: white !important;
    font-size: 18px;
    /* Add any other styling for the error message text */
  }

   .card {
    position: relative;
    overflow: hidden;
    max-width: 100%;
  }
  
   .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 300px; 
  } 
  .card-overlay .card-img-overlay{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 34px; /* Decreased padding to make it less obtrusive */
  }