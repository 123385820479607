/* HomeCardBody.css */
:root {
  --Background1-color: #333;
  --Text1-color: rgba(255, 255, 255, 0.9);
  --Box1-color: rgba(0, 0, 0, 0.1);
  --Background2-color: rgba(255, 255, 255, 0.9);
  --Text2-color: #777;
  --Box2-color: rgba(0, 0, 0, 0.1);
}

#card {
  height: 100vh;
  border: 0;
}
.menu-card-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white; /* Hintergrundfarbe des Headers */
}

/* Footer */
.menu-card-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white; /* Hintergrundfarbe des Footers */
}

.menu-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  overflow-y: auto; /* Füge diese Zeile hinzu */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/schuhExamples/Übersicht.jpg") no-repeat center center fixed;
  background-size: cover;
}

.background-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.menu-card-content-1 {
  width: 100%;
  padding: 20px;
  background-color: var(--Background1-color);
  color: var(--Text1-color);
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--Box1-color);
  margin: 10px;
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
}

.menu-card-content-2 {
  width: 75%;
  padding: 20px;
  background-color: var(--Background2-color);
  color: var(--Text2-color);
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--Box2-color);
  margin: 10px;
}

.menu-card-content-3 {
  width: 75%;
  padding: 20px;
  background-color: var(--Background1-color);
  color: var(--Text1-color);
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--Box1-color);
  margin: 10px;
}

.menu-card-content-3 p {
  text-align: center;
}
